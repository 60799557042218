<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-2">
        Which of the following play significant roles in the extent of injury or harm when exposed
        to a hazardous chemical? (More than 1 answer is possible)
      </p>

      <p class="mb-2" v-for="question in questions" :key="question.inputName">
        <v-checkbox v-model="inputs.studentAnswers" :value="question.value" class="pl-0 mb-n4 mt-0">
          <template v-slot:label>
            <stemble-latex :content="question.text" />
          </template>
        </v-checkbox>
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import STextarea from '@/common/components/STextarea.vue';

export default {
  name: 'ChemUCI1LC_Q4S2_Q10',
  components: {StembleLatex, STextarea},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        studentAnswers: [],
      },
      questions: [
        {text: 'The amount of chemical one is exposed to', value: 'a'},
        {text: 'The amount of time one is exposed to the chemical', value: 'b'},
        {text: 'How the chemical entered the body', value: 'c'},
        {text: 'The atmospheric pressure', value: 'd'},
        {text: 'The availability of safety equipment', value: 'e'},
        {text: 'The relative humidity', value: 'f'},
      ],
    };
  },
};
</script>
